<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          class="px-xl-2 mx-auto"
        >
          <vuexy-logo class="mb-2" />
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            {{ $t('forgot_password.title_main') }}
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('forgot_password.title_secondary') }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group
                :label="`${$t('forgot_password.form.email')}`"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
              >
                {{ $t('forgot_password.form.button_submit') }}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'login'}">
              <feather-icon icon="ChevronLeftIcon" />{{ $t('forgot_password.back_button') }}
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import { forgotPasswordService } from '@/services/auth/forgotPasswordService'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    VuexyLogo,
  },
  data() {
    return {
      forgotPasswordService: forgotPasswordService(this.$http),
      userEmail: '',
      sideImg: require('@/assets/images/bg_login.svg'),
      // validation
      required,
      email,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/bg_login.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      // eslint-disable-next-line consistent-return
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          const data = await {
            email: this.userEmail,
          }

          return this.forgotPasswordAction(data)
        }
      })
    },
    async forgotPasswordAction(data) {
      try {
        this.forgotPasswordService.post(data).then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Solicitação enviada com sucesso, verifique seu e-mail.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          setTimeout(() => {
            this.$router.push({ name: 'login' })
          }, 3000)
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Erro encontrado.',
            text: 'E-mail não encontrado',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
        this.$router.push({ name: 'login' })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
